import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "DDNS access to the camera does not work in Synology Software",
  "path": "/Frequently_Asked_Question/Synology_Remote_Access/",
  "dateChanged": "2020-04-29",
  "author": "Mike Polinowski",
  "excerpt": "For remote recording, I want to connect to an IN-8015 camera using an INSTAR DynDNS address within Surveillance Station Software",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - DDNS access to the camera does not work in Synology Software' dateChanged='2020-04-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='For remote recording, I want to connect to an IN-8015 camera using an INSTAR DynDNS address within Surveillance Station Software' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Synology_Remote_Access/' locationFR='/fr/Frequently_Asked_Question/Synology_Remote_Access/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: For remote recording, I want to connect to an IN-8015 camera using an INSTAR DynDNS address within `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/Synology_as_FTP_Server/"
      }}>{`Surveillance Station Software`}</a>{` on my Synology Disk Station. Unfortunately, the connection only works when I select MJPEG as the video format, and there is no way to transfer the sound. Within an internal network, the H.264 stream works without any issues.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Synology Surveillance Station captures the H.264 stream from your camera via RTSP. Therefore, the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`RTSP port`}</a>{` of the camera must be `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`enabled for remote access`}</a>{` and specified in the camera configuration of Surveillance Station. However, since the preset INSTAR models do not have an RTSP port field, you have to add your camera with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Custom`}</code>{` profile. There, enter the following in the RTSP path: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Username:Password@IP or DDNS-Address:RTSP-Port/12`}</code></pre></div>
    <p>{`e.g.: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`admin:instar@80.159.219.130:554/12
admin:instar@abcd12.ddns3-instar.de:554/12`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "755px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/438fea939c16e172259ba661eb1d72ea/cab43/Synology_INSTAR_Remote_Access_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACa0lEQVQ4y62TTU8UQRCG929yJoQEQrJeuHLGLGDgYIJ/AS4mRAMo0YgEUVSW/QCWmZ2ej+7p6ekZIJ4f7d5dUBMTTTw86XR3zdtv1VQ1kjRlQpplVLZkKAs+B5KuyOkKxZdQ0h4q+nHO11AhVEFdWcqy/AUpFY1u/4Lzbo92p0v/4pLCGKy1WB9kxpRU1mKKwu+zLENr7WONKanq2n8jpaSRJCl1XXN7c4MuDJdBRChSMl16ZGGRuuTsvMvJ6RnHJ5/o9S84a59zdHzCx89t3rw7IteaPM9pDK4DpFKjF1TO5fWQQSgIROKFpBc2tFortFotDg/f0+v1aLfbbG1tMTc3x9TUFGEYUhTFSNBhjEEXBcM4Jc4kIh0htUFpw+bmMzY21ul0OvT7fQaDAfv7ezSbTaanp4miaCQo4pgsk75OuS64uI64CiKuQkGUKqSpUKZmbW2NpaUldnd3vbvT01O2t7eZn59nYWGBOI59XRuxr2FFVVU+5UH4IBiIjECkiFSxvPzYf9xsPmJ9fZ2V1VUWFxeZmZlhdnb2IWVXSOfO1VDrgjzXvqaZVLjHMikJgpDDow8833nJ7v4BL/de8+rgLTsv9vzZ/sFblFKjn+LawYlNRB3OraN21JVvFWsrbu/uMGVFJjWZ0pT2Bl0VVOMMnWjjZ6HfG3XCw50Zr3bMrzH3gv+De8GJu39l4vLnMy9o/Pj8Gff672e2NNxWI8pxzL1gEA6JhMD1o0cIhsPofp8kib93jevWOBZ+PN91Y456CVfB0Mc4t36WpXQzWPxdmtZyV1s6ScWT4288PbnjOjWkSUruJuqHw+8PHTgnjjH8DgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/438fea939c16e172259ba661eb1d72ea/e4706/Synology_INSTAR_Remote_Access_01.avif 230w", "/en/static/438fea939c16e172259ba661eb1d72ea/d1af7/Synology_INSTAR_Remote_Access_01.avif 460w", "/en/static/438fea939c16e172259ba661eb1d72ea/b6759/Synology_INSTAR_Remote_Access_01.avif 755w"],
              "sizes": "(max-width: 755px) 100vw, 755px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/438fea939c16e172259ba661eb1d72ea/a0b58/Synology_INSTAR_Remote_Access_01.webp 230w", "/en/static/438fea939c16e172259ba661eb1d72ea/bc10c/Synology_INSTAR_Remote_Access_01.webp 460w", "/en/static/438fea939c16e172259ba661eb1d72ea/a149a/Synology_INSTAR_Remote_Access_01.webp 755w"],
              "sizes": "(max-width: 755px) 100vw, 755px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/438fea939c16e172259ba661eb1d72ea/81c8e/Synology_INSTAR_Remote_Access_01.png 230w", "/en/static/438fea939c16e172259ba661eb1d72ea/08a84/Synology_INSTAR_Remote_Access_01.png 460w", "/en/static/438fea939c16e172259ba661eb1d72ea/cab43/Synology_INSTAR_Remote_Access_01.png 755w"],
              "sizes": "(max-width: 755px) 100vw, 755px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/438fea939c16e172259ba661eb1d72ea/cab43/Synology_INSTAR_Remote_Access_01.png",
              "alt": "In der Synology Software funktioniert der DDNS Zugriff auf die Kamera nicht",
              "title": "In der Synology Software funktioniert der DDNS Zugriff auf die Kamera nicht",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`So the retrieval of the H.264- stream works and also with sound.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      